<template>
  <div class="structure_card" :class="{ flip: isFlip }">
    <!-- front face flip card -->
    <div
      class="structure_card_face structure_card_face-front d-flex flex-column align-center justify-start"
      :class="{
        'justify-center': !structure,
        'current-card': isCurrentStructure,
      }"
    >
      <avatar
        v-if="structure"
        :context="{ ID_Structure: structure.id }"
        size="140"
      />

      <div class="structure_card_title title-2 mt-4" v-if="structure">
        {{ structure.NomStructure }}
      </div>
      <div class="structure_card_title title-2 mt-4" v-else>
        Choisir une structure fédérale
      </div>

      <v-btn
        @click="changeCurrentSructure"
        :disabled="isCurrentStructure"
        class="arrow-btn"
        v-if="structure"
        icon
      >
        <v-icon color="lightgrey" size="28">mdi-arrow-right</v-icon>
      </v-btn>

      <div class="structure_card_rights my-1" v-if="structure && roleName">
        <v-chip color="blue text-white font-weight-bold" small>
          {{ roleName }}</v-chip
        >
      </div>
      <!-- <div
        class="structure_card_rights text-center mt-2"
        v-if="structure && moduleList && moduleList.length"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <div
                style="background: #e3e3e3; border-radius: 10px;"
                class="p-1"
              >
                <span
                  v-for="(mod, index) in moduleList.slice(0, 2)"
                  :key="index"
                >
                  {{ mod.NomModule
                  }}<span v-if="index != moduleList.slice(0, 2).length - 1"
                    >,
                  </span> </span
                >...
              </div>
            </div>
          </template>
          <span v-for="(mod, index) in moduleList" :key="index">
            {{ mod.NomModule
            }}<span v-if="index != moduleList.length - 1">, </span>
          </span>
        </v-tooltip>
      </div> -->

      <v-btn
        class="structure_card_manage-icon"
        @click="isFlip = true"
        large
        icon
      >
        <font-awesome-icon icon="cog" />
      </v-btn>
    </div>

    <!-- back face flip card  -->
    <div
      class="structure_card_face structure_card_face-back d-flex flex-column align-center justify-center"
      :class="{ currentStructure: isCurrentStructure }"
    >
      <div class="structure_card_title title text-white mb-4" v-if="!structure">
        Choisir votre structure
      </div>

      <simpleSelect
        :items="availableStructures"
        v-model="selectedStructure"
        item_text="NomStructure"
        item_value="ID_StructureUtilisateur"
        @onChange="updateStructureSlot"
        placeholder="Sélectionner"
        v-if="!structure"
      />

      <v-btn class="btn btn-primary mt-4" @click="isFlip = false">
        Terminer
      </v-btn>

      <v-btn
        class="structure_card_trash-icon"
        @click="updateStructureSlot()"
        large
        icon
        v-if="structure"
      >
        <font-awesome-icon icon="trash-alt" />
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";
import { sleep } from "@/plugins/utils";
import avatar from "@/components/Common/Common__Avatar.vue";

import simpleSelect from "@/components/utils/select.vue";

export default {
  components: {
    simpleSelect,
    avatar,
  },

  data: () => ({
    isFlip: false,
    selectedStructure: {},
  }),

  computed: {
    ...mapGetters("structure", ["currentStructureId"]),
    ...mapGetters("basics", ["getDocumentType"]),

    structure() {
      return this.structureUtilisateur
        ? this.structureUtilisateur.STR_Structure
        : null;
    },

    isCurrentStructure() {
      return this.structure
        ? this.structure.id === this.currentStructureId
        : false;
    },

    avatarStructure() {
      let categorie_id = this.getDocumentType("avatar_structure").id;
      if (
        this.structure.DOC_Documents.filter(
          (doc) => doc.ID_Type_Document === categorie_id
        )[0]
      ) {
        return this.structure.DOC_Documents.filter(
          (doc) => doc.ID_Type_Document === categorie_id
        )[0];
      }
      return {
        CheminDocument:
          "https://ui-avatars.com/api/?name=" +
          this.structure.NomStructure +
          "&size=256",
      };
    },

    moduleList() {
      let moduleAccess = [];

      if (this.structureUtilisateur.ADR_DroitsType) {
        moduleAccess = this.structureUtilisateur.ADR_DroitsType;
      }
      return moduleAccess;
    },

    roleName() {
      if (this.structureUtilisateur.ADR_RoleType) {
        return this.structureUtilisateur.ADR_RoleType.NomRole;
      }
      return false;
    },
  },

  async created() {
    // Trick to remove class after initialising form
    this.setData();
  },
  mounted() {
    document.querySelectorAll(".arrow-btn").forEach((btn) => {
      btn.classList.remove("v-btn--disabled");
    });
  },

  methods: {
    ...mapActions("structure", [
      "setCurrentStrucureFederal",
      "updateOrdreStructureFederal",
    ]),

    setData() {
      if (this.structure) {
        this.selectedStructure = {
          NomStructure: this.structure.NomStructure,
          ID_StructureUtilisateur: this.structureUtilisateur.id,
        };
      } else {
        this.selectedStructure = {};
      }
    },

    async updateStructureSlot(data) {
      try {
        const payload = {
          ID_StructureUtilisateur: data ?? this.structureUtilisateur.id,
          OrdreStructureFederal: data ? this.structureSlot : null,
        };

        await this.updateOrdreStructureFederal(payload);
        success_notification("Modification enregistrée !");
        await sleep(0);
        this.isFlip = false;
        this.setData();
      } catch (e) {
        error_notification("Oups, une erreur es survenue...");
        this.isFlip = false;
      }
    },

    changeCurrentSructure() {
      this.setCurrentStrucureFederal(this.structure.id);
    },
  },

  props: {
    structureUtilisateur: {
      type: Object,
    },
    availableStructures: {
      type: Array,
    },
    structureSlot: {
      type: Number,
    },
  },
};
</script>

<style lang="scss">
.current-card {
  background: #00a9e725 !important;
}
.structure_card {
  button.v-btn[disabled] {
    color: #00abe7;
  }
}
</style>
