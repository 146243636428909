<template>
  <div>
    <AcceuilPremiereConnexionRoleFederal
      v-if="hasRoleFederalHome && isFirstConnectionHome"
    ></AcceuilPremiereConnexionRoleFederal>
    <AcceuilRoleFederal v-else-if="hasRoleFederalHome"></AcceuilRoleFederal>
    <AcceuilLicencie v-else></AcceuilLicencie>
  </div>
</template>

<script>
import AcceuilRoleFederal from "./AcceuilRoleFederal.vue";
import AcceuilLicencie from "./AcceuilLicencie.vue";
import AcceuilPremiereConnexionRoleFederal from "./AcceuilPremiereConnexionRoleFederal.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    AcceuilRoleFederal,
    AcceuilLicencie,
    AcceuilPremiereConnexionRoleFederal,
  },

  data: () => ({
    mounted: false,
  }),

  async created() {
    this.setAppTitle({ title: "" });
    this.mounted = true;
  },

  methods: {
    ...mapActions("app", ["setAppTitle"]),
  },

  computed: {
    ...mapGetters("app", ["appUserMode"]),
    ...mapGetters("user", ["hasRoleFederalOn"]),
    ...mapGetters("structure", ["currentStructureId", "isFirstConnection"]),
    ...mapGetters("saisons", ["saisons", "currentSaisonId"]),

    hasRoleFederalHome() {
      if (!this.mounted) return false;
      return (
        this.hasRoleFederalOn(this.currentStructureId) &&
        this.appUserMode == "federal"
      );
    },

    isFirstConnectionHome() {
      if (!this.mounted) return false;
      return this.isFirstConnection && this.appUserMode == "federal";
    },
  },
};
</script>
