<template>
  <v-card height="100%" class="mx-auto accueil__news-card __font-normal">
    <v-app-bar dark color="#0646a5">
      <v-spacer></v-spacer>
      <v-toolbar-title>
        <span style="color:#6ed7fc" class="font-weight-bold"> myFFME </span>
        <span class="font-weight-bold"> {{ title }}</span></v-toolbar-title
      >
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-container class="pt-0 scroll" style="height:65vh;">
      <div class="accueil__list-wrapper" style="padding-left: 0;">
        <div v-if="news && news.length" class="accueil__news-list">
          <div
            v-for="(item, index) of news"
            :key="index"
            class="accueil__news-teaser px-2 py-4"
          >
            <NewsItem
              class="newsItem"
              :news="item"
              :showName="showName"
            ></NewsItem>
          </div>
        </div>
        <div v-else class="accueil__no-news pt-4">
          <div v-if="loading">Loading</div>
          <div v-else>Actuellement aucune actualité</div>
        </div>
      </div>
    </v-container>
    <div class="pagination_containers">
      <v-divider></v-divider>
      <div v-if="max !== 1">
        <v-pagination
          v-model="page"
          class="my-4 pagination__actualité"
          :length="max"
          :total-visible="5"
        ></v-pagination>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { apolloWSClient } from "@/vue-apollo";
export default {
  name: "CardActualitees",
  components: {
    NewsItem: () => import("@/components/Accueil/FFMENews/NewsItem.vue"),
  },

  data: () => ({
    news: [],
    loading: true,
    typeFFMEID: 1,
    page: 1,
    newsTotal: [],
    max: 1,
    clubID: [],
    structureIDs: [],
    FFMEIdStructure: [1318],
    newsFFMEtest: null,
    subscribeData: [],
  }),

  computed: {
    ...mapGetters("structure", ["currentStructure"]),
  },
  async created() {
    await this.setData();
  },

  methods: {
    ...mapActions("structure", [
      "getNewsByStructureID",
      "getFFMENews",
      "searchStructure",
    ]),
    ...mapActions("News", ["getListTypeNews"]),

    async ffmeTypeNewsID() {
      let responses = await this.getListTypeNews();
      let responseFilter = responses.filter(
        (response) => response.NomTypeNews === "ffme"
      );
      this.typeFFMEID = responseFilter && responseFilter[0].id;
    },
    async getFFMESubscription() {
      let response = await apolloWSClient
        .subscribe({
          query: require("@/graphql/News/get_ffme_news.gql"),
          variables: {
            ID_TypeNews: 1,
          },
        })
        .subscribe({
          next(data) {
            // if (data.data.todos.length) {
            //   // check if the received todo is already present
            //   if (data.data.todos[0].id !== that.todos[0].id) {
            //     that.newTodosCount = that.newTodosCount + data.data.todos.length;
            //   }
            // }
            if (data && data.data && data.data.STR_News) {
              this.subscribeData = data.data.STR_News;
            }
          },
          error(err) {
            console.error("err", err);
          },
        });

      return this.subscribeData;
    },
    async setData() {
      let response = await this.searchStructure({
        whereClause: { id: { _eq: 1318 } },
      });
      // let test = await this.getFFMESubscription();
      this.FFMEIdStructure = response?.map((res) => res.id);
      let today = new Date();
      let selected = new Date(this.selectedDate);
      let newFeedDate = this.hasSelectedDate ? selected : today;
      // get news with denomination FFME
      // this.newsFFMEtest = await this.getFFMENews({
      //   ID_TypeNews: this.typeFFMEID,
      // });
      let variables = [];
      // si la valeur des structures n'est pas encore présente on affiche newsFFME
      variables = this.structureIDS
        ? [...this.structureIDS, ...this.newsStructureIds, ...this.FFMEIdStructure]
        : [...this.FFMEIdStructure];

      //gets newsFFME
      let newsFFME = await this.getNewsByStructureID({
        ID_Structure: variables,
      });
      this.newsTotal = [...newsFFME];
      // test max pagination
      this.max =
        this.newsTotal.length % 7 !== 0
          ? parseInt(this.newsTotal.length / 7) + 1
          : parseInt(this.newsTotal.length / 7);
      // wee get only the news with a date de parution passed
      this.newsTotal = this.newsTotal.filter((n) => {
        return new Date(n.DateParution) <= newFeedDate;
      });
      //we sort by date de parution
      this.newsTotal = this.newsTotal.sort(
        (a, b) => new Date(b.DateParution) - new Date(a.DateParution)
      );
      //w we keep only the last 6
      let deparSlice = (this.page - 1) * 7;
      let finSlice = this.page * 7;
      this.news = this.newsTotal.slice(deparSlice, finSlice);
    },
  },
  watch: {
    selectedDate() {
      this.setData();
    },
    page() {
      this.setData();
    },
    structureIDS() {
      this.setData();
      this.loading = false;
    },
  },

  props: {
    title: {
      type: String,
      default: "Title card",
    },

    newsStructureIds: {
      type: Array,
    },
    showName: {
      type: Boolean,
      default: false,
    },
    selectedDate: {
      type: String,
      default: null,
    },
    hasSelectedDate: {
      type: Boolean,
      default: false,
    },
    clubStructureIds: {
      type: Array,
    },
    // isFFMEFeeds: {
    //   type: Boolean,
    // },
    structureIDS: {
      type: Array,
    },
  },
};
</script>
<style lang="scss">
.pagination__actualité .v-pagination__item--active {
  background-color: #01abe8 !important;
}
.newsItem {
  height: calc(72vh / 8);
}
.scroll {
  overflow: auto;
  direction: rtl;
}
</style>
