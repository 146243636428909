<template>
  <div>
    <hr />
    <div v-if="loading">Chargement...</div>
    <div v-else-if="hasError">Une erreur est survenue pendant le chargement de la dernière vidéo youtube</div>
    <div v-else>
      <div class="youtube-video-player">
        <iframe
          :src="`https://www.youtube.com/embed/${videoId}`"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data: () => ({
    loading: true,
    hasError: false,
    videoId: null,
  }),

  async created() {
    try {
        const response = await axios.get(`${process.env.VUE_APP_FFME_BACK_URL}/api/last_youtube_video`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME)}`,
          },
        });

        this.videoId = response.data.data.videoId;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.hasError = true;
      }
  }
}
</script>

<style lang="scss">
  .youtube-video-player {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    margin: 0 auto;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
</style>
