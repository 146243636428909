<template>
  <v-row class="structure_card-container scrollable-container">
    <v-col cols="6" md="4" v-for="n in 6" :key="n">
      <structureItem
        :structureUtilisateur="getStructureUtilisateurOrder(n)"
        :structureSlot="n"
        :availableStructures="availableStructures"
      ></structureItem>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

import structureItem from "@/components/Structure/Structure__CardRoleFederal.vue";

export default {
  components: {
    structureItem,
  },

  computed: {
    ...mapGetters("structure", ["withRoleFederal"]),

    availableStructures() {
      return this.withRoleFederal
        .filter((obj) => !obj.OrdreStructureFederal)
        .map((obj) =>
          Object.assign({
            NomStructure: obj.STR_Structure?.NomStructure,
            ID_StructureUtilisateur: obj.id,
          })
        );
    },
  },

  methods: {
    getStructureUtilisateurOrder(n) {
      return this.withRoleFederal.filter(
        (obj) => obj.OrdreStructureFederal === n
      )[0];
    },
  },
};
</script>
