<template>
  <v-row class="home_first_connection scrollable" v-if="!loading" no-gutters>
    <v-col cols="12" sm="4" lg="4" class="__bkgr-blue-light d-flex flex-column justify-space-between">
      <v-row>
        <v-col cols="12" class="mt-10e">
          <Avatar :context="{ID_Utilisateur: currentUserId }" size="160"></Avatar>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="home_first_connection_info">
          <p>Informations générales</p>
          <BasicInfoUser
            :context="{
              ID_Utilisateur: currentUserId,
              ID_Saison: currentSaison.id,
            }"
          ></BasicInfoUser>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <div class="home_first_connection_link-account">
            <font-awesome-icon icon="cog" />
            <a href="javascript:void(0)" @click.prevent="switchSpace" >Modifier mes paramètres</a >
          </div>
        </v-col>
      </v-row>
    </v-col>

    <v-col
      cols="12"
      sm="8"
      lg="8"
      class="home_first_connection_hero-image d-flex flex-column justify-space-between"
    >
      <v-row>
        <v-col cols="12" sm="8" lg="8" class="align-self-start">
          <span class="hero_image-categorie">À la une</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="8" lg="8" class="align-self-center">
          <h1 class="hero_image-title mb-3">
            Vous pouvez personnaliser votre page principale
          </h1>
          <p class="hero_image-subtitle mb-10">
            Il est possible de paramétrer votre home page en choisissant quelles
            rubriques vous souhaitez faire apparaître.<br />Venez découvrir
            cette fonctionnalité.
          </p>
          <v-btn class="btn btn-blue-light px-10" @click.prevent="go" >J'y vais</v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="8" lg="8" class="align-self-end">
          <v-btn class="ma-2" outlined fab color="grey darken-1">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn class="ma-2" outlined fab color="white">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { error_notification } from "@/plugins/notifications";

import Avatar from "@/components/Common/Common__Avatar.vue";
import BasicInfoUser from "@/components/User/User__BasicInfo.vue";

export default {
  components: {
    Avatar,
    BasicInfoUser,
  },

  data: () => ({
    loading: true,
  }),

  computed: {
    ...mapGetters("structure", ["currentStructureId", "currentStructure"]),
    ...mapGetters("user", ["currentUserId"]),
    ...mapGetters("saisons", ["selectedSaison", "currentSaison"]),
  },

  async created() {
    this.loading = false;
  },

  methods: {
    ...mapActions("structure", ["updateFirstConnection"]),

    ...mapActions("app", ["updateAppUserModeThenRoute"]),

    async go() {
      try {
        const userId = this.currentUserId;
        await this.updateFirstConnection(userId);
      } catch (e) {
        error_notification("Oups, une erreur es survenue...");
      }
    },

    switchSpace() {
      const payload = {
        user_mode: "licencie",
        route: "mon_compte__modification_cordonnees",
      };

      this.updateAppUserModeThenRoute(payload);
    },
  },
};
</script>
